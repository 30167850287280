<template>
    <el-dialog title="Återbetalning" top="7vh" :visible.sync="visible" width="30%" :before-close="handleClose">
        <div class="bg-gray-50 border border-gray-200 rounded-lg p-3 mb-3 flex" v-loading="$waiting.is('refundloading')">
            <div>
                <div class="font-thin">Klient</div>
                <div class="w-40 font-thin">Faktura</div>
                <div class="font-thin">Kund</div>
                <div class="font-thin">Fakturabelopp</div>
                <div class="font-thin">Kvar att betala</div>
            </div>
            <div>
                <div class="font-thin truncate">{{ paymentData.clientName }}</div>
                <div class="font-thin">{{ paymentData.invoiceNumber }}</div>
                <div class="font-thin">{{ paymentData.customerName }}</div>
                <div class="font-thin">{{ invoiceAmountText }}</div>
                <div class="font-thin">{{ invoiceBalanceText }}</div>
            </div>
        </div>
        <el-form ref="form" :rules="rules" :model="form" label-width="210px" label-position="left" class="border border-gray-200 rounded-lg p-3" v-loading="$waiting.is('refundupdating')">
            <el-form-item label="Skapa betalningsunderlag:" prop="sendPainFile">
                <el-col :span="11">
                    <el-radio-group v-model="form.sendPainFile" size="mini" @change="updatePaymentDate()">
                        <el-radio-button label="true">Ja</el-radio-button>
                        <el-radio-button label="false">Nej</el-radio-button>
                    </el-radio-group>
                </el-col>
                <el-col :span="11">
                    <iTag content="Skapa ett underlag till PAIN-fil, kan ses i Alan" />
                </el-col>
            </el-form-item>
            <el-form-item label="Bokför:" size="mini" prop="booking">
                <el-col :span="11">
                    <el-radio-group v-model="form.booking">
                        <el-radio-button label="true">Ja</el-radio-button>
                        <el-radio-button label="false">Nej</el-radio-button>
                    </el-radio-group>
                </el-col>
                <el-col :span="11">
                    <iTag content="Bokför återbetalning" />
                </el-col>
            </el-form-item>
            <el-form-item label="Kundreskontra:" size="mini" prop="ledger">
                <el-col :span="11">
                    <el-radio-group v-model="form.ledger">
                        <el-radio-button label="true">Ja</el-radio-button>
                        <el-radio-button label="false">Nej</el-radio-button>
                    </el-radio-group>
                </el-col>
                <el-col :span="11">
                    <iTag content="Lägg till återbetalning i kundreskontran" />
                </el-col>
            </el-form-item>
            <el-form-item label="Justera faktura:" size="mini" prop="adjustInvoice">
                <el-col :span="11">
                    <el-radio-group v-model="form.adjustInvoice">
                        <el-radio-button label="true">Ja</el-radio-button>
                        <el-radio-button label="false">Nej</el-radio-button>
                    </el-radio-group>
                </el-col>
                <el-col :span="11">
                    <iTag content="Justera balansen och status på fakturan" />
                </el-col>
            </el-form-item>
            <el-form-item v-if="form.sendPainFile === 'true'" label="Betalnings typ" prop="accountType">
                <el-select v-model="form.accountType" class="w-full">
                    <el-option v-for="item in accountTypes" :key="item.value" :value="parseInt(item.value)" :label="item.name" />
                </el-select>
            </el-form-item>
            <el-form-item label="Konto nr" prop="accountNumber" v-if="form.sendPainFile === 'true'">
                <el-input v-model="form.accountNumber" />
            </el-form-item>
            <el-form-item label="Mottagare" prop="recipientName" v-if="form.sendPainFile === 'true'">
                <el-input disabled v-model="form.recipientName" />
            </el-form-item>
            <el-form-item label="Att betala:" prop="amount">
                <InputCurrency v-model="form.amount" custom-class="w-full text-sm rounded border border-solid input-border" />
            </el-form-item>
            <el-form-item v-if="form.sendPainFile === 'true' || form.booking === 'true' || form.ledger === 'true'" label="Betalningsdag" prop="paymentDate">
                <DateInputField :disabled="disableDate" :pre-selected-value="form.paymentDate" @change="form.paymentDate = $event" />
            </el-form-item>
        </el-form>
        <span slot="footer" class="dialog-footer">
            <el-button @click="handleClose()">Cancel</el-button>
            <el-button type="primary" @click="onSubmit()">Confirm</el-button>
        </span>
    </el-dialog>
</template>
<script>
import Api from "../invoices.api";
import moment from "moment";

export default {
    props: {
        visible: {
            type: Boolean,
            default: false,
        },
        sourceId: {
            type: String,
            default: "",
        },
    },

    watch: {
        visible() {
            if (this.visible) {
                this.getAccountTypes();
                this.getPaymentValues();
            }
        },
    },

    data() {
        return {
            paymentData: {},
            accountTypes: [],
            form: {
                sendPainFile: "",
                booking: "",
                ledger: "",
                adjustInvoice: true,
                amount: "",
                paymentDate: "",
                accountType: 0,
                accountNumber: null,
                recipientName: null,
            },
            rules: {
                sendPainFile: [{ required: true, message: "Nödvändig", trigger: "blur" }],
                booking: [{ required: true, message: "Nödvändig", trigger: "blur" }],
                ledger: [{ required: true, message: "Nödvändig", trigger: "blur" }],
                adjustInvoice: [{ required: true, message: "Nödvändig", trigger: "blur" }],
                paymentDate: [{ required: true, message: "Nödvändig", trigger: "blur" }],
                amount: [{ required: true, message: "Nödvändig", trigger: "blur" }],
                accountType: [{ required: true, message: "Nödvändig", trigger: "blur" }],
                accountNumber: [{ required: true, message: "Nödvändig", trigger: "blur" }],
                recipientName: [{ required: true, message: "Nödvändig", trigger: "blur" }],
            },
        };
    },

    components: {
        InputCurrency: () => import(/* webpackChunkName: "InputCurrency" */ "@/components/InputCurrency.vue"),
        DateInputField: () => import(/* webpackChunkName: "DateInputField" */ "@/components/DateInputField.vue"),
        iTag: () => import(/* webpackChunkName: "InformationTag" */ "./InformationTag.vue"),
    },

    methods: {
        handleClose() {
            this.resetForm();
            this.$emit("close");
        },

        async getAccountTypes() {
            this.accountTypes = await Api.getAccountTypes();
        },

        async onSubmit() {
            await new Promise(resolve => this.$refs.form.validate(valid => valid && resolve()));
            this.$waiting.start("refundupdating");
            await Api.createRefund(this.sourceId, this.form);
            this.$waiting.end("refundupdating");
            this.$notify.success({ title: "Utfört" });
            this.handleClose();
        },

        updatePaymentDate() {
            if (this.form.sendPainFile === "false") {
                this.$set(this.form, "paymentDate", "");
            } else if (this.form.sendPainFile === "true") {
                this.$set(this.form, "paymentDate", moment(new Date()).format("YYYY-MM-DD"));
            }
        },

        async getPaymentValues() {
            this.$waiting.start("refundloading");
            this.paymentData = await Api.getPaymentValues(this.sourceId);
            if (this.paymentData.balanceInSek != 0) {
                this.form.amount = -this.paymentData.balanceInSek;
            }
            this.form.recipientName = this.paymentData.customerName;
            this.$waiting.end("refundloading");
        },

        resetForm() {
            this.$refs.form.resetFields();
            this.paymentData = {};
            this.form = {};
        },
    },

    computed: {
        disableDate() {
            if (this.form.sendPainFile === "true") {
                return true;
            }
            return false;
        },

        isSEKInvoice() {
            if (this.paymentData.currencyCode === "SEK") return true;
            return false;
        },

        invoiceAmountText() {
            let result = "";
            result += `${this.$options.filters.swedishNumberFormat(this.paymentData.amountInSek)} SEK`;
            if (!this.isSEKInvoice) {
                result += ` (${this.$options.filters.swedishNumberFormat(this.paymentData.amountInOriginalCurrency)} ${this.paymentData.currencyCode})`;
            }
            return result;
        },

        invoiceBalanceText() {
            let result = "";
            result += `${this.$options.filters.swedishNumberFormat(this.paymentData.balanceInSek)} SEK`;
            if (!this.isSEKInvoice) {
                result += ` (${this.$options.filters.swedishNumberFormat(this.paymentData.balanceInOriginalCurrency)} ${this.paymentData.currencyCode})`;
            }
            return result;
        },
    },
};
</script>
