<template>
    <el-dialog width="1300px" top="30px" title="Kundinbetalning" :visible.sync="visible" :before-close="closeModal" :close-on-click-modal="false">
        <div class="flex flex-row">
            <div style="width: 600px">
                <p class="mx-4 button is-warning is-light mb-5" v-if="formData.reminderCounter != 0">
                    <span class="icon is-small">
                        <i class="fas fa-info-circle" />
                    </span>
                    Påminnelse {{ formData.reminderCounter }} skickad
                    <span v-if="formData.reminderCounter == 2">
                        , påminnelseavgift: {{ reminderForDisplay | swedishNumberFormat }} {{ formData.currencyCode }}, räntebelopp: {{ interestForDisplay | swedishNumberFormat }}
                        {{ formData.currencyCode }}
                    </span>
                </p>
                <div class="px-8 rounded" v-loading="$waiting.is('paymentModalLoading')">
                    <el-form ref="form" :model="formData">
                        <div class="pb-4">
                            <div class="bg-gray-50 border border-gray-200 rounded-lg p-3 mb-3 flex">
                                <div>
                                    <div class="text-sm">Klient:</div>
                                    <div class="text-sm w-40">Faktura:</div>
                                    <div class="text-sm">Kund</div>
                                    <div class="text-s">Fakturabelopp</div>
                                    <div class="text-s">Kvar att betala</div>
                                </div>
                                <div>
                                    <div class="text-sm font-thin truncate">{{ formData.clientName }}</div>
                                    <div class="text-sm font-thin">{{ formData.invoiceNumber }}</div>
                                    <div class="text-sm font-thin">{{ formData.customerName }}</div>
                                    <div class="text-s font-thin">{{ invoiceAmountText }}</div>
                                    <div class="text-s font-thin">{{ invoiceBalanceText }}</div>
                                </div>
                                <div />
                            </div>
                        </div>
                        <div class="pb-5" v-if="overPaidInvoicePresent">
                            <el-radio-group v-model="formData.invoicePaymentType" @change="clearOverPaidInvoiceObj()">
                                <el-radio :label="1">Manuell betalning</el-radio>
                                <el-radio :label="2">Välj från överbetald faktura</el-radio>
                            </el-radio-group>
                        </div>
                        <div class="pb-5">
                            <el-form-item v-if="isMovePayment" label="Överbetalt">
                                <el-select v-model="formData.overPaidInvoice" placeholder="--- Välj över betald faktura ---" class="flex">
                                    <el-option v-for="overPaidInvoice in formData.overPaidInvoices" :label="overPaidInvoice.name" :value="overPaidInvoice" :key="overPaidInvoice.value" />
                                </el-select>
                            </el-form-item>
                            <div class="bg-gray-50 border border-gray-200 rounded-lg p-3 mb-3 flex" v-if="Object.keys(formData.overPaidInvoice).length != 0">
                                <div>
                                    <div class="text-sm w-40">Faktura:</div>
                                    <div class="text-sm">Kund:</div>
                                    <div class="text-sm" v-if="formData.overPaidInvoice.currencyCode != 'SEK'">Överbetalt belopp {{ formData.overPaidInvoice.currencyCode }}:</div>
                                    <div class="text-sm">Överbetalt belopp SEK:</div>
                                </div>
                                <div>
                                    <div class="text-sm font-thin">{{ formData.overPaidInvoice.invoiceNumber }}</div>
                                    <div class="text-sm font-thin">{{ formData.overPaidInvoice.customerName }}</div>
                                    <div class="text-sm font-thin" v-if="formData.overPaidInvoice.currencyCode != 'SEK'">{{ displayOriginalOverPaidAmount }}</div>
                                    <div class="text-sm font-thin">{{ displayOverPaidAmountSek }}</div>
                                </div>
                            </div>
                            <div class="flex">
                                <el-form-item v-if="isSEKInvoice || isMoneyPayment" label="Inbetalt av kund (SEK)" class="w-1/2 pr-2">
                                    <InputCurrency v-model="formData.paidAmount" custom-class="w-full text-sm py-1 px-2 rounded border border-solid input-border" />
                                </el-form-item>
                                <el-form-item :label="'Kvar att fördela (' + this.formData.currencyCode + ')'" class="w-1/2 pl-2">
                                    <InputCurrency :value="restAmount" :disabled="true" custom-class="w-full text-sm py-1 px-2 rounded border border-solid input-border" />
                                </el-form-item>
                            </div>
                            <div class="flex">
                                <el-form-item v-if="!isSEKInvoice" :label="paidAmountOriginalCurrencyText" class="w-1/2 pr-2">
                                    <InputCurrency v-model="formData.originalPaidAmount" custom-class="w-full text-sm py-1 px-2 rounded border border-solid input-border" />
                                </el-form-item>
                                <el-form-item v-if="formData.reminderCounter == 2" :label="reminderFeeText" class="w-1/2 pl-2">
                                    <InputCurrency v-model="formData.reminderFeesEUR" custom-class="w-full text-sm py-1 px-2 rounded border border-solid input-border" />
                                </el-form-item>
                            </div>
                            <div class="flex">
                                <el-form-item v-if="isMoneyPayment" label="Bankavgift (SEK)" class="w-1/2 pr-2">
                                    <InputCurrency v-model="formData.bankCharge" custom-class="w-full text-sm py-1 px-2 rounded border border-solid border-input-border" />
                                </el-form-item>
                                <el-form-item v-if="formData.reminderCounter == 2" :label="interestText" class="w-1/2 pl-2">
                                    <InputCurrency v-model="formData.interestIncomeEUR" custom-class="w-full text-sm py-1 px-2 rounded border border-solid input-border" />
                                </el-form-item>
                            </div>
                            <div class="flex">
                                <el-form-item label="Transaktionsdatum" class="w-1/2 pr-2">
                                    <DateInputField :pre-selected-value="formData.transactionDate" @change="formData.transactionDate = $event" />
                                </el-form-item>
                                <el-form-item v-if="restAmount > 0 || formData.overPayment > 0" :label="'Överbetalning (' + this.formData.currencyCode + ')'" class="w-1/2 pl-2">
                                    <InputCurrency v-model="formData.overPayment" custom-class="w-full text-sm py-1 px-2 rounded border border-solid input-border" />
                                </el-form-item>
                            </div>
                        </div>
                    </el-form>
                    <p class="p-2 font-medium text-lg" v-if="customerReceivableOriginal == formData.balanceInOriginalCurrency && overPaymentSek <= 0">Fakturan kommer bli fullbetald</p>
                    <p class="p-2 font-medium text-lg" v-if="customerReceivableOriginal > 0 && customerReceivableOriginal < formData.balanceInOriginalCurrency">Fakturan kommer bli delbetald</p>
                    <p class="p-2 font-medium text-lg" v-if="overPaymentSek > 0">Fakturan kommer bli överbetald</p>
                </div>
            </div>
            <div class="pl-9 px-8" style="width: 700px">
                <VoucherPreview :transactions="transactions" :client-id="clientId" />
                <p class="p-2">Balans: {{ balanceSek | swedishNumberFormat }}</p>
                <p slot="footer" class="absolute bottom-0 right-0 p-5">
                    <el-button @click="register" type="success" plain :disabled="isFormNotReady" :loading="$waiting.is('modal.adding')">Registera</el-button>
                    <el-button @click="closeModal" type="primary">Stäng</el-button>
                </p>
            </div>
        </div>
    </el-dialog>
</template>
<script>
import moment from "moment";
import Api from "../invoices.api";

export default {
    props: {
        visible: {
            type: Boolean,
            default: false,
        },
        sourceId: {
            type: String,
            default: "",
        },
    },

    watch: {
        visible(value) {
            if (!value) return;
            this.getPaymentValues();
        },
    },

    components: {
        VoucherPreview: () => import(/* webpackChunkName: "VoucherPreview" */ "@/components/VoucherPreview.vue"),
        InputCurrency: () => import(/* webpackChunkName: "InputCurrency" */ "@/components/InputCurrency.vue"),
        DateInputField: () => import(/* webpackChunkName: "DateInputField" */ "@/components/DateInputField.vue"),
    },

    data() {
        return {
            reminderForDisplay: 0,
            interestForDisplay: 0,
            overPaidInvoicePresent: false,
            clientId: null,
            formData: {
                transactionDate: "",
                amountInOriginalCurrency: 0,
                amountInSek: 0,
                balanceInSek: 0,
                balanceInOriginalCurrency: 0,
                paidAmount: "",
                originalPaidAmount: "",
                bankCharge: 0,
                reminderFeesEUR: 0,
                interestIncomeEUR: 0,
                reminderCounter: 0,
                overPayment: 0,
                currencyCode: "",
                overPaidInvoices: [],
                overPaidInvoice: {},
                invoicePaymentType: 1,
                invoiceNumber: 0,
                customerName: "",
                clientName: "",
            },
        };
    },

    created() {
        this.formData.transactionDate = moment(new Date()).format("YYYY-MM-DD");
    },

    methods: {
        clearOverPaidInvoiceObj() {
            this.formData.paidAmount = "";
            this.formData.originalPaidAmount = "";
            this.formData.overPaidInvoice = {};
        },

        resetForm() {
            this.formData.transactionDate = moment(new Date()).format("YYYY-MM-DD");
            this.formData.amountInOriginalCurrency = 0;
            this.formData.amountInSek = 0;
            this.formData.balanceInSek = 0;
            this.formData.balanceInOriginalCurrency = 0;
            this.formData.paidAmount = "";
            this.formData.originalPaidAmount = "";
            this.formData.bankCharge = 0;
            this.formData.reminderFeesEUR = 0;
            this.formData.interestIncomeEUR = 0;
            this.formData.reminderCounter = 0;
            this.formData.overPayment = 0;
            this.formData.currencyCode = "";
            this.clientId = null;
            this.formData.overPaidInvoices = [];
            this.formData.overPaidInvoice = {};
            this.formData.invoicePaymentType = 1;
        },

        async register() {
            let createPainTransaction = false;
            if (this.isMoneyPayment) {
                const confirm = await this.$dialog
                    .title(
                        `Efter att betalningen har bokförts vill du att\n en pain-fil skapas för att överföra ${this.$options.filters.swedishNumberFormat(
                            this.withdrawalToClient,
                        )} SEK från klientmedelskontot till klientens företagskonto?`,
                    )
                    .confirm("");
                if (confirm) {
                    const confirm2 = await this.$dialog.title("Är du helt säker? :-)").confirm("");
                    if (confirm2) {
                        createPainTransaction = true;
                    }
                }
            }

            await new Promise(resolve => this.$refs.form.validate(valid => valid && resolve()));

            this.$waiting.start("modal.adding");
            try {
                const invoiceDto = {
                    invoiceId: parseInt(this.sourceId),
                    bookingDate: this.formData.transactionDate,
                    customerReceivablePaidSek: this.customerReceivablePaid,
                    customerReceivableOriginalCurrency: this.customerReceivableOriginal,
                    currencyCode: this.formData.currencyCode,
                    balanceInSek: this.formData.balanceInSek,
                    balanceInOriginalCurrency: this.formData.balanceInOriginalCurrency,
                    overPaymentSek: this.overPaymentSek,
                    overPaymentOriginalCurrency: this.formData.overPayment,
                    withdrawalToClient: this.withdrawalToClient,
                    createPainTransaction: createPainTransaction,
                    transactions: this.transactions,
                };

                if (this.isMovePayment) {
                    invoiceDto.overPaidInvoiceId = this.formData.overPaidInvoice.value;
                    invoiceDto.customerReceivableInvoiceOverpaidSek = this.customerReceivableInvoiceOverpaidSek;
                    invoiceDto.overPaidInvoiceBalanceInSek = -this.formData.overPaidInvoice.overPaidAmountSek;
                    invoiceDto.overPaidInvoiceBalanceInOriginalCurrency = -this.formData.overPaidInvoice.originalOverPaidAmount;

                    await Api.movePayment(invoiceDto.invoiceId, invoiceDto);
                } else {
                    await Api.registerPayment(invoiceDto.invoiceId, invoiceDto);
                }

                this.$notify.success({ title: "Utfört" });
                this.closeModal();
            } finally {
                this.$waiting.end("modal.adding");
            }
        },

        closeModal() {
            this.resetForm();
            this.$emit("close");
        },

        async getPaymentValues() {
            this.$waiting.start("paymentModalLoading");
            const paymentData = await Api.getPaymentValues(this.sourceId);

            this.clientId = paymentData.clientId;
            this.formData.amountInOriginalCurrency = paymentData.amountInOriginalCurrency;
            this.formData.amountInSek = paymentData.amountInSek;
            console.log("🚀 - file: InvoicePayModal.vue - line 273 - getPaymentValues - this.formData.amountInSek", this.formData.amountInSek);

            this.formData.balanceInSek = paymentData.balanceInSek;
            console.log("🚀 - file: InvoicePayModal.vue - line 275 - getPaymentValues - this.formData.balanceInSek", this.formData.balanceInSek);
            this.formData.balanceInOriginalCurrency = paymentData.balanceInOriginalCurrency;

            this.formData.reminderCounter = paymentData.reminderCounter;
            this.reminderForDisplay = paymentData.reminderFee;
            this.interestForDisplay = paymentData.interestAmount;
            this.formData.invoiceNumber = paymentData.invoiceNumber;
            this.formData.customerName = paymentData.customerName;
            this.formData.currencyCode = paymentData.currencyCode;
            if (paymentData.overPaidInvoices.length != 0) this.overPaidInvoicePresent = true;
            this.formData.overPaidInvoices = paymentData.overPaidInvoices;
            this.formData.clientName = paymentData.clientName;
            this.$waiting.end("paymentModalLoading");
        },
    },

    computed: {
        isFormNotReady() {
            if (this.isMoneyPayment) {
                if (!this.formData.paidAmount || this.formData.paidAmount == 0) return true;
            }
            if (!this.originalPaidAmount || this.originalPaidAmount == 0) return true;
            if (this.restAmount > 0) return true;
            if (!this.formData.transactionDate) return true;
            // if (this.isMovePayment && this.formData.originalPaidAmount > this.formData.overPaidInvoice.originalOverPaidAmount) return true;
            // if (this.isMoneyPayment && this.formData.paidAmount > this.formData.overPaidInvoice.overPaidAmountSek) return true;

            return false;
        },

        transactions() {
            let result = [];
            let customerReceivableText = "";
            if (this.customerReceivableInvoiceOverpaidSek > 0) {
                result.push({ account: 1510, debit: this.customerReceivableInvoiceOverpaidSek, text: `Fakturanummer ${this.formData.overPaidInvoice.invoiceNumber}`, credit: 0 });
                customerReceivableText = `Fakturanummer ${this.formData.invoiceNumber}`;
            }
            if (this.customerReceivablePaid > 0) result.push({ account: 1510, debit: 0, text: customerReceivableText, credit: this.customerReceivablePaid });
            if (this.overPaymentSek > 0) result.push({ account: 1510, text: "Överbetalning", debit: 0, credit: this.overPaymentSek });
            if (this.currencyDiff < 0) result.push({ account: 3960, debit: 0, credit: -this.currencyDiff });
            if (this.currencyDiff > 0) result.push({ account: 7960, debit: this.currencyDiff, credit: 0 });
            if (this.formData.bankCharge > 0) result.push({ account: 6570, debit: this.formData.bankCharge, credit: 0 });
            if (this.formData.reminderFeesEUR > 0) result.push({ account: 3580, debit: 0, credit: this.reminderFee });
            if (this.formData.interestIncomeEUR > 0) result.push({ account: 8313, debit: 0, credit: this.interest });
            if (this.withdrawalToClient > 0) result.push({ account: 1930, debit: this.withdrawalToClient, credit: 0 });

            return result;
        },

        isSEKInvoice() {
            if (this.formData.currencyCode === "SEK") return true;
            return false;
        },

        originalPaidAmount() {
            if (this.formData.currencyCode === "SEK") {
                return this.formData.paidAmount;
            }
            return this.formData.originalPaidAmount;
        },

        reminderFee() {
            return (this.formData.reminderFeesEUR * this.paymentCurrencyRate).roundToEven2();
        },

        interest() {
            return (this.formData.interestIncomeEUR * this.paymentCurrencyRate).roundToEven2();
        },

        bankCharge() {
            return (this.formData.bankCharge * 1).roundToEven2();
        },

        restAmount() {
            const amount = (this.originalPaidAmount - this.customerReceivableOriginal - this.formData.reminderFeesEUR - this.formData.interestIncomeEUR - this.formData.overPayment).roundToEven2();
            return amount;
        },

        // restAmountSek() {
        //     return (this.restAmount * this.paymentCurrencyRate).roundToEven2() - this.formData.overPayment;
        // },

        overPaymentSek() {
            return (this.formData.overPayment * this.paymentCurrencyRate).roundToEven2();
        },

        paymentCurrencyRate() {
            if (this.originalPaidAmount === 0) return 1;
            return this.formData.paidAmount / this.originalPaidAmount;
        },

        invoiceCurrencyRate() {
            if (this.formData.amountInOriginalCurrency === 0) return 1;
            return this.formData.amountInSek / this.formData.amountInOriginalCurrency;
        },

        invoiceOverpaidCurrencyRate() {
            if (this.formData.overPaidInvoice.originalOverPaidAmount === 0) return 1;
            return this.formData.overPaidInvoice.overPaidAmountSek / this.formData.overPaidInvoice.originalOverPaidAmount;
        },

        customerReceivableInvoiceOverpaidSek() {
            if (this.isMoneyPayment) return 0;
            if (this.customerReceivableInvoiceOverpaidOriginal == -this.formData.overPaidInvoice.originalOverPaidAmount) return -this.formData.overPaidInvoice.overPaidAmountSek;
            const result = (this.customerReceivableInvoiceOverpaidOriginal * this.invoiceOverpaidCurrencyRate).roundToEven2();
            return result;
        },

        customerReceivableInvoiceOverpaidOriginal() {
            if (this.isMovePayment) {
                //if (this.formData.balanceInOriginalCurrency <= 0) return 0;
                let amount = this.originalPaidAmount; // this.restAmount > 0 ? this.restAmount : 0;
                if (amount >= this.formData.balanceInOriginalCurrency) amount = this.formData.balanceInOriginalCurrency;
                return amount;
            } else {
                return 0;
            }
        },

        customerReceivableOriginal() {
            if (this.formData.balanceInOriginalCurrency <= 0) return 0;
            let amount = (this.originalPaidAmount - this.formData.reminderFeesEUR - this.formData.interestIncomeEUR - this.formData.overPayment).roundToEven2(); // this.restAmount > 0 ? this.restAmount : 0;
            if (amount >= this.formData.balanceInOriginalCurrency) amount = this.formData.balanceInOriginalCurrency;
            return amount;
        },

        customerReceivablePaid() {
            if (this.customerReceivableOriginal === this.formData.balanceInOriginalCurrency) {
                return this.formData.balanceInSek;
            }
            const customerReceivablePaid = (this.invoiceCurrencyRate * this.customerReceivableOriginal).roundToEven2();
            if (isNaN(customerReceivablePaid)) return 0;
            return customerReceivablePaid;
        },

        currencyDiff() {
            if (this.isSEKInvoice) return 0;
            if (this.isMovePayment) {
                return -(-this.customerReceivablePaid + this.customerReceivableInvoiceOverpaidSek).roundToEven2();
            } else {
                return -(-this.customerReceivablePaid + -this.overPaymentSek + this.bankCharge + -this.reminderFee + -this.interest + this.withdrawalToClient).roundToEven2();
            }
        },

        paidAmountOriginalCurrencyText() {
            return "Inbetalt av kund (" + this.formData.currencyCode + ")";
        },

        reminderFeeText() {
            return "Påminnelseavgift (" + this.formData.currencyCode + ")";
        },

        interestText() {
            return "Ränteintäkt (" + this.formData.currencyCode + ")";
        },

        withdrawalToClient() {
            if (this.isMovePayment) return 0;
            return (this.formData.paidAmount - this.formData.bankCharge).roundToEven2();
        },

        balanceSek() {
            let balance = (
                -this.customerReceivablePaid +
                -this.overPaymentSek +
                this.currencyDiff +
                this.bankCharge +
                -this.reminderFee +
                -this.interest +
                this.withdrawalToClient +
                this.customerReceivableInvoiceOverpaidSek
            ).roundToEven2();
            return balance;
        },

        isMovePayment() {
            return this.formData.invoicePaymentType === 2;
        },

        isMoneyPayment() {
            return this.formData.invoicePaymentType === 1;
        },

        displayOriginalOverPaidAmount() {
            return this.$options.filters.swedishNumberFormat(-this.formData.overPaidInvoice.originalOverPaidAmount);
        },

        displayOverPaidAmountSek() {
            return this.$options.filters.swedishNumberFormat(-this.formData.overPaidInvoice.overPaidAmountSek);
        },
        invoiceAmountText() {
            let result = "";
            result += `${this.$options.filters.swedishNumberFormat(this.formData.amountInSek)} SEK`;
            if (!this.isSEKInvoice) {
                result += ` (${this.$options.filters.swedishNumberFormat(this.formData.amountInOriginalCurrency)} ${this.formData.currencyCode})`;
            }
            return result;
        },
        invoiceBalanceText() {
            let result = "";
            result += `${this.$options.filters.swedishNumberFormat(this.formData.balanceInSek)} SEK`;
            if (!this.isSEKInvoice) {
                result += ` (${this.$options.filters.swedishNumberFormat(this.formData.balanceInOriginalCurrency)} ${this.formData.currencyCode})`;
            }
            return result;
        },
    },
};
</script>
